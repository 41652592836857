import fetchAPI from "./fetchAPI"

export async function fetchNaturkontaktToken(token, captcha) {
  const { fetchNaturkontaktToken } = await fetchAPI(
    `query($token: String!, $captcha: String!) {
      fetchNaturkontaktToken(token: $token, captcha: $captcha) {
        status,
        token,
        url,
        domain
      }
    }`,
    {
      variables: { token, captcha }
    },
    true
  )
  return fetchNaturkontaktToken
}
