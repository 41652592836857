import { useEffect, useState } from "react"
import Close from "@/components/common/icons/close"
import { useCopy } from "@/lib/contexts/appContext"
import { usePathData } from "@/lib/contexts/appContext"
import styles from "./notice.module.css"
import Storage from "@/lib/storage"

export const Notice = () => {
  const pathData = usePathData()
  const { general } = useCopy()
  const notice = general?.notice
  const [hasMounted, setMounted] = useState(false)
  const [dismissed, setDismissed] = useState(false)

  const dismissNotice = (value) => {
    if (value) {
      Storage.set("notice", true, "session")
    }
    setDismissed(true)
  }

  useEffect(() => {
    let isMounted = true

    if (isMounted) {
      const hasDismissed = Storage.get("notice", false, "session")
      if (notice?.dismissable && hasDismissed) {
        setDismissed(true)
      }

      setMounted(true)
    }
    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathData.path])

  if (!notice?.active) {
    return null
  }

  if (dismissed) {
    return null
  }

  if (!hasMounted) {
    return null
  }

  return (
    <div className={styles.notice}>
      <div className="inner-wrapper">
        <span>{notice.tag}</span>
        <h4>{notice.heading}</h4>
        <p>{notice.text} </p>
        {notice.dismissable && (
          <div className="dismiss" onClick={() => dismissNotice(true)}>
            <Close className="close" />
          </div>
        )}
      </div>
    </div>
  )
}
