import { useEffect, useState } from "react"
import { useRouter } from "next/router"
import { usePathData } from "@/lib/contexts/appContext"
import { getProps } from "@/lib/component"
import { updateMemberSettings } from "@/lib/api/member"
import { getMemberToken, deleteMemberCookie } from "@/lib/helpers/member"
import { fetchMemberProps } from "@/lib/api/member"

export const useMember = () => {
  const router = useRouter()
  const pathData = usePathData()
  const memberProps = getProps(pathData.props, "member")
  const { member, menu } = memberProps

  const [isLoading, setLoading] = useState(false)
  const [hasMounted, setMounted] = useState(false)
  const [isLoggedIn, setLoggedIn] = useState(member?.isMember ?? false)
  const [menuItems, setMenuItems] = useState(menu ?? [])
  const [settings, setSettings] = useState(member?.settings ?? {})
  const [token, setToken] = useState(getMemberToken())
  const [email, setEmail] = useState(member?.email ?? null)
  const [firstName, setFirstName] = useState(member?.firstName)
  const [lastName, setLastName] = useState(member?.lastName)
  const [fullName, setFullName] = useState(null)

  useEffect(() => {
    setFullName([firstName, lastName].filter(Boolean).join(" "))
  }, [firstName, lastName])

  const onLogout = (e) => {
    e.preventDefault()
    deleteMemberCookie()
    setLoggedIn(false)
    router.replace(router.asPath)
  }

  useEffect(() => {
    let isMounted = true
    if (isMounted) {
      const token = getMemberToken()

      if (isLoggedIn && !token) {
        setLoggedIn(false)
      }

      // We're have a non-state / outside SSG, but have a token
      if (!isLoggedIn && token) {
        setLoading(true)
        fetchMemberProps(token).then((response) => {
          if (response?.status) {
            setLoggedIn(true)
            setSettings(response?.settings)
            setMenuItems(response?.menu?.items ?? [])
            setFirstName(response?.firstName)
            setLastName(response?.lastName)
            setEmail(response?.email)
            setToken(token)
          }
          setMounted(true)
          setLoading(false)
          return
        })
      }

      // If this is truthy we have SSG auth
      if (member?.isMember) {
        setLoggedIn(true)
      }

      setMounted(true)
    }
    return () => {
      isMounted = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathData, isLoggedIn])

  const saveSettings = async (captcha, key, data, callback) => {
    const token = getMemberToken()
    const response = await updateMemberSettings(token, captcha, {
      [key]: data
    })

    if (typeof callback === "function") {
      callback(response, key, data)
    }
  }

  return {
    token: token,
    isLoading: isLoading,
    isLoggedIn: isLoggedIn,
    hasMounted: hasMounted,
    firstName: firstName,
    lastName: lastName,
    fullName: fullName,
    email: email,
    menuItems: menuItems,
    events: settings?.events ?? {
      default: false,
      subject: [],
      type: [],
      organization: [],
      favorites: []
    },
    articles: settings?.articles ?? { category: [], type: [] },
    saveSettings: saveSettings,
    onLogout: onLogout
  }
}
